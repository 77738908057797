import React from "react"
import { SKILLSImages } from "../gatsby-images/skillsImages"
import Fade from 'react-reveal/Fade'
import UseAnimations from "react-useanimations";
import arrowRightCircle from 'react-useanimations/lib/arrowRightCircle'
import arrowDown from 'react-useanimations/lib/arrowDown'
import { Link } from "gatsby"


const vision = () => {
  return (
    <div className="projects">
      <ProjectHeader />
      <Fade bottom delay={10}>
      <W1 />
      </Fade>
      <Fade bottom delay={20}>
      <W3/>
      </Fade>
      <Fade bottom delay={30}>
      <W5 />
      </Fade>
      <Fade bottom delay={40}>
      <W6 />
      </Fade>
      <Dribbble />
    </div>
  )
}


const W1 = () => {
  return (
 
    <div className="work-container">
      <div className="container-work">
      <div className="work-sub">
      <h6>Visual Design & UI/UX Design</h6>
      </div>
      <div className="work-title">
            <h5>Cultural and Creative</h5>
          </div>
       
          <div className="work-description">
            <ui>
              <li>
             <div className='linee'></div>I create unique and singular corporate identity Guidelines either evolve continuous in the times.</li>
              <li>
             <div className='linee'></div>Eco branding is to make mentality evolve and provide a new mindsets of your company.</li>
              <li>
              <div className='linee'></div> 
              I establish user journey, user mapping and identify the user personas.
              </li>
              <li>
              <div className='linee'></div> 
              I create the design system and the UX Kits following some rules depending the country and UX rules such Fitts' Law.</li>
              <li>
              <div className='linee'></div> 
              I like the research and developing prototype to always provide the best final render and see the design become real.
              </li>
            </ui>
      
      <div className="btn-hire">
          <a href="mailto:raphael@paaolms.com" className="Hello">
          Learn more  
          </a>
          <UseAnimations animation={arrowRightCircle} size={32} autoplay={true} loop={true} speed={0.3} strokeColor="#F2F3F4"style={{}} />
          </div>

          </div>
       
          </div>
       

        <div className="workimage">
        <div className="up">
        <SKILLSImages id={1} />
        </div>
    <div className="background-b"></div>
    </div>
</div>
  
  )
}

const W2 = () => {
  return (
 
    <div className="work-container">
      <div className="container-work">
  
     
      <div className="work-sub">
      
      <h6>MARKETING</h6>
  
      </div>
  
  
      <div className="work-title">
            <h5></h5>
          </div>
       
          <div className="work-description">
            <ui>
              <li>
              Email Marketing
             </li>
              <li>
              Email Software
              </li>
              <li>
              Influencer Package</li>
              <li>Eco branding come up to make your products evolve</li>
            </ui>
      
      <div className="btn-hire">
          <a href="mailto:raphael@paaolms.com" className="Hello">
          Learn more  
          </a>
          <UseAnimations animation={arrowRightCircle} size={32} autoplay={true} loop={true} speed={0.3} strokeColor="#F2F3F4"style={{}} />
          </div>

          </div>
       
          </div>
       

        <div className="workimage">
        <div className="up">
        <SKILLSImages id={2} />
        </div>
    <div className="background-b"></div>
    </div>
</div>
  
  )
}



const W3 = () => {
  return (
 
    <div className="work-container">
      <div className="container-work">
  
     
      <div className="work-sub">
      
      <h6>NETWORK ARCHITECTURE</h6>
  
      </div>
  
  
      <div className="work-title">
            <h5>Online Presence</h5>
          </div>
       
          <div className="work-description">
            <ui>
              <li>
              <div className='linee'></div> 
              Website Builders & Integrated Strategies.
              </li>
              <li>
              <div className='linee'></div> 
              Application Development.
              </li>
              <li>
              <div className='linee'></div> 
              Progressive Web Application.
             </li>
              <li>
              <div className='linee'></div> 
              Software development and Cloud Architecture.
              </li>
            </ui>
      
      <div className="btn-hire">
          <a href="mailto:raphael@paaolms.com" className="Hello">
          Learn more  
          </a>
          <UseAnimations animation={arrowRightCircle} size={32} autoplay={true} loop={true} speed={0.3} strokeColor="#F2F3F4"style={{}} />
          </div>

          </div>
       
          </div>
       

        <div className="workimage">
        <div className="up">
        <SKILLSImages id={3} />
        </div>
    <div className="background-b"></div>
    </div>
</div>
  
  )
}

const W4 = () => {
  return (
 
    <div className="work-container">
      <div className="container-work">
  
     
      <div className="work-sub">
      
      <h6>PROMOTE</h6>
  
      </div>
  
  
      <div className="work-title">
            <h5>ATTRACT CUSTOMERS</h5>
          </div>
       
          <div className="work-description">
            <ui>
              <li>
              <div className='linee'></div> 
              Integrated Strategies</li>
              <li>
              <div className='linee'></div> 
              Digital facilitator</li>
              <div className='linee'></div> 
              <li>I change how brands relate with customers online</li>
            </ui>
      
      <div className="btn-hire">
          <a href="mailto:raphael@paaolms.com" className="Hello">
          Learn more  
          </a>
          <UseAnimations animation={arrowRightCircle} size={32} autoplay={true} loop={true} speed={0.3} strokeColor="#F2F3F4"style={{}} />
          </div>

          </div>
       
          </div>
       

        <div className="workimage">
        <div className="up">
        <SKILLSImages id={4} />
        </div>
    <div className="background-b"></div>
    </div>
</div>
  
  )
}



const W5 = () => {
  return (
 
    <div className="work-container">
      <div className="container-work">
  
     
      <div className="work-sub">
      
      <h6>ADVERTISING & MARKETING</h6>
  
      </div>
  
  
      <div className="work-title">
            <h5>Reinvent your business</h5>
          </div>
       
          <div className="work-description">
            <ui>
              <li>
              <div className='linee'></div> 
              I define integrated strategies to attract your customers such Email Software and Marketing.
              </li>
              <li>
              <div className='linee'></div> 
              Vertical Integration Strategy to Retail, Fit-retail to local customer.
              </li>
              <li>
              <div className='linee'></div> 
              I change how brands relate with customers online.
             </li>
            </ui>
      
      <div className="btn-hire">
          <a href="mailto:raphael@paaolms.com" className="Hello">
          Learn more  
          </a>
          <UseAnimations animation={arrowRightCircle} size={32} autoplay={true} loop={true} speed={0.3} strokeColor="#F2F3F4"style={{}} />
          </div>

          </div>
       
          </div>
       

        <div className="workimage">
        <div className="up">
        <SKILLSImages id={5} />
        </div>
    <div className="background-b"></div>
    </div>
</div>
  
  )
}

const W6 = () => {
  return (
 
    <div className="work-container">
      <div className="container-work">
  
     
      <div className="work-sub">
      
      <h6>PRODUCT DESIGN</h6>

      </div>
  
  
      <div className="work-title">
            <h5>Evolve your product</h5>
          </div>
       
          <div className="work-description">
            <ui>
              <li>
              <div className='linee'></div> 
              Identified product and market risk: Understanding and solving problems and needs.
              </li>
              <li>
              <div className='linee'></div> 
              Strategy for generating traction: Product/market suitability, Using Sean Ellis Test For Measuring  Product/Market.
              </li>
              <li>
              <div className='linee'></div> 
              Analyse business risk: Create a viable product by understanding the problem, defining the solution and validating it qualitatively and quantitatively.
              </li>
              <li>
              <div className='linee'></div> 
              Creating a link with customers: development, quality control, submission, testing and launch are part of the process.
              </li>
              <li>
              <div className='linee'></div> 
              Launch, growth and expansion.
              </li>
            </ui>
      
      <div className="btn-hire">
          <a href="mailto:raphael@paaolms.com" className="Hello">
          Learn more  
          </a>
          <UseAnimations animation={arrowRightCircle} size={32} autoplay={true} loop={true} speed={0.3} strokeColor="#F2F3F4"style={{}} />
          </div>

          </div>
       
          </div>
       

        <div className="workimage">
        <div className="up">
        <SKILLSImages id={6} />
        </div>
    <div className="background-b"></div>
    </div>
</div>
  
  )
}



const ProjectHeader = () => {
  return (
    <Fade top delay={10}>
    <div className="project-header">
      <div className="container">
        <div className="project-inner">
          <div className="project-title">
            <h5>How do I work?</h5>
          </div>
          <div className="project-description">
            <p>
            Your needs and product risk are identified. We create a viable business to solve the product risk, thereby ensuring that you gain traction.
            </p>
          </div>
         
        </div>
      </div>
    </div>
    </Fade>
  )
}

 
   
const Dribbble = () => {
  return (
    <div className="dribble-showcase">
      <div className="container">
        <div className="dribble-showcase-inner">
          <h4>Check out my past website?</h4>
          <span>
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://designlensestudio.netlify.com"
              className="drib"
            >
            Take a Look<UseAnimations animation={arrowRightCircle} size={32} autoplay={true} loop={true} speed={0.2} strokeColor="#F2F3F4"style={{}} />
            </a>
          </span>
        </div>
      </div>
    </div>
  )
}

export default vision
